import React from "react";

//Styles
import "./Banner.css";



const Banner = () => {

  return (
    <section className="container">
      <div  className="test">
      <iframe  src="https://www.youtube.com/embed/s5tuYTKJtOk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen 
        className="t"       
      />
        </div>
    </section>
  );
};

export default Banner;
