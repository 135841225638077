const Language = [{
    EN: {
        title: 'Sign in / Sign Up',
        signIn: 'Sign in',
        signUp: 'Sign Up',
        noAccount: "You don't have account?",
        withAccount: "Already have an account?",
        forgotPass: 'Forgot your password?',
        restorePass: 'Restore',
        required: 'Required',
        shortPass: 'Password must be 6 symbols',
        firstName: "First name",
        email: "Email",
        password: "Password",
        reset: 'Check your email for further recovery',
    },
    ES: {
        title: 'Vous connecter / vous inscrire',
        signIn: 'Vous connecter',
        signUp: 'Sign Up',
        noAccount: "Vous n'avez pas de compte ?",
        withAccount: "Vous avez déjà un compte?",
        forgotPass: 'Mot de passe oublié?',
        restorePass: 'Restaurer',
        required: 'Obligatoire',
        shortPass: "Le mot de passe doit être composé de 6 symboles",
        firstName: "Nom",
        email: "E-mail",
        password: "Mot de passe",
        reset: 'Vérifiez votre courrier électronique pour une récupération ultérieure',
    },
    DE: {
        title: 'Anmelden / Registrieren',
        signIn: 'Anmelden',
        signUp: 'Registrieren',
        noAccount: 'Sie haben kein Konto?',
        withAccount: "Sie haben bereits ein Konto?",
        forgotPass: 'Passwort vergessen?',
        restorePass: 'Restore',
        required: 'Wiederherstellen',
        shortPass: 'Das Passwort muss aus 6 Zeichen bestehen',
        firstName: "Name",
        email: "Email",
        password: "Passwort",
        reset: 'Überprüfen Sie Ihre E-Mail für weitere Genesung',
    },
    UA: {
        title: 'Увійти / Зареєструватися',
        signIn: 'Увійти',
        signUp: 'Зареєструватися',
        noAccount: 'У вас немає акаунту?',
        withAccount: "Вже є аккаунт?",
        forgotPass: 'Забули пароль?',
        restorePass: 'Відновити',
        required: "Обов'язково",
        shortPass: 'Пароль повинен містити 6 символів',
        firstName: "Ім'я",
        email: "Пошта",
        password: "Пароль",
        reset: 'Для подальшого відновлення перейдіть до пошти',
    },
}]
export default Language;