import React, { useEffect } from "react";
import { connect } from "react-redux";

//Language
import Language from "./Language";

//Router
import { Link } from "react-router-dom";

//Redux
import { changeStateModalSignUp } from "../../Redux/Actions/HeaderActions";

//Images
import logoMob from "../../images/AforehandStudio_logo_v2.webp";
import logo from "../../images/logo.png";
import gplay from "../../images/GooglePlay.png";

//Buttons
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { orange } from "@material-ui/core/colors";

//Select
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

//Icons
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import "./Header.css";
import { asyncLogout } from "../../Redux/Actions/SignInActions";

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});
const checkPathNameForLinkBySwitchingLanguages = (l) => {

  var arr = ['', null, undefined, 'EN', 'UA', 'ES', 'DE', 'en', 'ua', 'es', 'de'];
  for (var i = 0; i < arr.length; i++) {
    if (l == arr[i]) return false;
  }

  return true;

}
const Header = ({ dispatch, changeLanguage, stateLanguage, token, name }) => {
  console.log(name)
  //destructuring language file
  const [languages] = Language;
  return (
    <header className="header-body">
      <div className="container">
        <div className="header-content">
          <div className="row" style={{width:'80%'}}>
            
              <a href="https://studio.aforehand.com.ua/main" target="_blanck" className="col"  style={{width:'20%'}}>
                <img src={logoMob}  alt="Logo" />
                </a>
                
                <Link to="/"  className="col" style={{marginLeft:'33%',width:'20%'}}>
                <img src={logo} className="col-3" alt="logo"
                  
                />
              </Link>
            
          </div>
          <div className="header-buttons">

            {/* <p className='name-header'>{name ? name : null}</p> */}
            <ThemeProvider theme={theme}>
              {Boolean(token) ? (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  endIcon={<ExitToAppIcon />}
                  className="header__sign-out"
                  onClick={() => dispatch(asyncLogout())}
                >
                  {stateLanguage ? languages[stateLanguage].buttonLogout : null}
                </Button>
              ) : (
                <Button
                  type="button"
                  onClick={() => dispatch(changeStateModalSignUp(true))}
                  variant="contained"
                  color="primary"
                  startIcon={<PersonIcon />}
                  className="header__sign-in"
                >
                  {stateLanguage ? languages[stateLanguage].buttonLogin : null}
                </Button>
              )}
            </ThemeProvider>
            <FormControl>
              <NativeSelect
                inputProps={{
                  name: "name",
                  id: "lang",
                }}
                onChange={(e) => {
                  var pathname = document.location.pathname.split('/')[1];
                  if (checkPathNameForLinkBySwitchingLanguages(pathname)) {
                    changeLanguage('EN');
                    //changeLanguage(e.target.value);
                    pathname += '/'
                  }
                  else {
                    pathname = '';
                  }
                  var newLink = document.location.origin + "/" + pathname + e.target.value.toLowerCase();
                  document.location.href = newLink;

                }}
                value={stateLanguage}
              >
                <option value="EN">En</option>
                <option value="DE">De</option>
                <option value="ES">Es</option>
                <option value="UA">Ua</option>
              </NativeSelect>
            </FormControl>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({ SignInReducer }) => {
  return {
    token: SignInReducer.token,
    name: SignInReducer.name
  }
}

export default connect(mapStateToProps)(Header);
