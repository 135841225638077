import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import CardOfProduct from "../../Containers/CardOfProduct/CardOfProduct";
import MainPage from "../../Containers/MainPage/MainPage";
import PrivacyPolicy from "../../Containers/PrivacyPolicy/PrivacyPolicy";
import Help from "../../Containers/Help/Help";
import ResetPass from "../../Containers/ResetPassword/ResetPass";
import Coop from "../../Components/Coop/Cooperation"


const RootRouter = ({ stateLanguage, changeLanguage }) => {
    return (
        <Switch>
            <Route path="/card-of-product/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <CardOfProduct stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <CardOfProduct stateLanguage={language.toUpperCase()} />
                    }}
            />
            <Route path="/privacy-police/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <PrivacyPolicy stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <PrivacyPolicy stateLanguage={language.toUpperCase()} />
                    }}
            />

            <Route path="/delete-user-data/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <Help stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <Help stateLanguage={language.toUpperCase()} />
                    }}
            />
            <Route path="/ResetPassword/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <ResetPass stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <ResetPass stateLanguage={language.toUpperCase()} />
                    }}
            />
            <Route path="/Cooperation/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <Coop stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <Coop stateLanguage={language.toUpperCase()} />
                    }}
            />
            <Route path="/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <MainPage stateLanguage={stateLanguage} />
                        }
                        changeLanguage(language.toUpperCase());
                        return <MainPage stateLanguage={language.toUpperCase()} />
                    }}
            />
            <Redirect to={(stateLanguage && stateLanguage.length == 2) ? "/" + stateLanguage.toLowerCase() : '/:language?'} />
        </Switch>
    );
};

export default RootRouter;