import { React } from 'react';
import './Spinner.css';

const Spinner = () => {
    return (
        <div className='parent'>
            <div class="loadingio-spinner-pulse-s6x2jzg27kl "><div class="ldio-r5t29llr5oj">
                <div></div><div></div><div></div>
            </div></div>
        </div>
    );
};

export default Spinner;