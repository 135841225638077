import React from 'react'

const Cooperation = ({ stateLanguage, token }) => {
  return (
    <main className="main">
      <div className='container'>
        rere
      </div>

    </main>
  );
}

export default Cooperation;