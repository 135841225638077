import React from 'react'


const Help = ({stateLanguage,token}) => {
  return (
    <main className="main">
        <p>For delete your user data from server just send email to the addres m.odrinskaya@gmail.com</p>
    </main>
  );
}

export default Help;